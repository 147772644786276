import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

import HomePage from "../src/components/HomePage/HomePage";
import AboutUs from "../src/components/AboutUs/AboutUs";
import Workers from "../src/components/Workers/Workers";
import Services from "../src/components/Services/Services";
import Contact from "../src/components/Contact/Contact";
import Error from "../src/components/Error/Error";

import PainFree from "../src/components/PainFree/PainFree";
import Manual from "../src/components/Manual/Manual";
import Lymph from "../src/components/Lymph/Lymph";
import Wellness from "../src/components/Wellness/Wellness";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/o-nama" element={<AboutUs />} />
          <Route path="/djelatnici" element={<Workers />} />
          <Route path="/usluge" element={<Services />} />

          <Route path="/usluge/pain-free-program" element={<PainFree />} />
          <Route path="/usluge/manualne-tehnike" element={<Manual />} />
          <Route path="/usluge/limfna-drenaza" element={<Lymph />} />
          <Route path="/usluge/wellness" element={<Wellness />} />

          <Route path="/kontakt" element={<Contact />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </div>

      <CookieConsent
        location="bottom"
        buttonText="U redu"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "white",
          color: "black",
          borderTop: "#3baeb3 solid 6px",
          fontSize: "11pt",
        }}
        buttonStyle={{
          background: "#3baeb3",
          color: "white",
          fontSize: "12pt",
          borderRadius: "20px",
          border: "gold solid 4px",
        }}
        expires={150}
      >
        Kako bismo poboljšali vaše i korisničko iskustvo drugih korisnika, na
        ovoj stranici koristimo kolačiće, datoteke kojima ne određujemo vaš
        identitet, već im pristupamo u skladu s europskim uredbama o zaštiti
        podataka i isključivo s prvonavedenom namjerom.
      </CookieConsent>
    </Router>
  );
}

export default App;
