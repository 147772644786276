import React from "react";
import { Link } from "react-router-dom";
import "./Error.css";

import logo from "../../files/studiomaja.png";

function Error() {
  console.log(window.location.href);

  return (
    <div className="error">
      <img
        src={logo}
        alt="Logo studija FizioMaja za usluge fizikalne terapije, manualne tehnike i druge oblike pomoći za unaprjeđenje fizičkog zdravlja i prevencije bolova u kostima, zglobovima, mišićima i kod drugih ozljeda"
        draggable="false"
      />
      <h1>Error 404</h1>
      <h3>
        Na lokaciji <em>{window.location.href}</em> ne nalazi se ništa. Molimo
        vas da provjerite URL koji ste unijeli jer ste vjerojatno otišli na
        lokaciju na kojoj nema ničega i nije prisutan ikakav sadržaj. Ne
        brinite, ovakva poteškoća ne predstavlja nikakvu smetnju, prekid veze
        ili neki drugi nedostatak tehničke prirode.
      </h3>
      <h4>
        Možete se vratiti na početnu stranicu studija FizioMaja pritiskom na{" "}
        <Link to="/usluge">vrati me na popis usluga</Link>.
      </h4>

      <a href="mailto:maja.zaborski@gmail.com" className="transport">
        POŠALJITE NAM IZRAVNO E-MAIL
      </a>
    </div>
  );
}

export default Error;
