import React, { useEffect } from "react";
import "./AboutUs.css";
import { Link } from "react-router-dom";

import intro1 from "../../files/logo.png";
import one from "../../files/1.png";

import presoterapija from "../../files/Presoter.pdf"
import limfnadrenaza from "../../files/Limfna drenaža - prikaz.pdf"

import Navigation from "../Navigation/Navigation";
import HomeAbout from "../HomeAbout/HomeAbout";
import Footer from "../Footer/Footer";

function AboutUs() {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

  return (
    <div className="about-us">
      <Navigation />

      <div className="about-intro">
        <h1>O studiju</h1>
        <img
          src={intro1}
          alt="Prikaz opreme za vježbanje, manualne tehnike zaštite i unaprjeđenja zdravlja i fizikalnu terapiju za starije i mlađe osobe s problemima s kostima i zglobovima"
          draggable="false"
        />
      </div>

      <HomeAbout />
      <img
          src={one}
          className="description-image"
          alt="Dodatni detalji o radnom vremenu i mogućnosti naručivanja i/ili osobnog dolaska u studio FizioMaja na fizikalnu terapiju i druge metode u ponudi kojima se štiti vaše zdravlje"
          draggable="false"
        />

        <div className="pdf-organise">
            <a href={limfnadrenaza} target="_blank" rel="noopener noreferrer">Priručnik o limfnoj drenaži i detaljima tehnike koju koristimo (PDF)</a>
            <a href={presoterapija} target="_blank" rel="noopener noreferrer">Više informacija o presoterapiji i njezinoj ulozi (PDF)</a>
        </div>

        <Link to="/" className="link-back">Povratak na početnu</Link>
      <Footer />
    </div>
  );
}

export default AboutUs;
