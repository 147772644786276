import React, { useEffect } from "react";
import "./Services.css";

import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";

import logo from "../../files/logo.png";
import { Link } from "react-router-dom";

function Services() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="services">
      <Navigation />

      <div className="services-intro">
        <h1>Usluge studija FizioMaja</h1>
        <img
          src={logo}
          alt="Logo studija FizioMaja za usluge fizikalne terapije, manualne tehnike i druge oblike pomoći za unaprjeđenje fizičkog zdravlja i prevencije bolova u kostima, zglobovima, mišićima i kod drugih ozljeda"
          draggable="false"
        />

        <ul>
          <Link className="serv-link" to="/usluge/pain-free-program">
            <li className="item">Pain free program</li>
          </Link>
          <Link className="serv-link" to="/usluge/manualne-tehnike">
            <li className="item">Manualne tehnike</li>
          </Link>
          <Link className="serv-link" to="/usluge/limfna-drenaza">
            <li className="item">Limfna drenaža</li>
          </Link>
          <Link className="serv-link" to="/usluge/wellness">
            <li className="item">Wellness program</li>
          </Link>
        </ul>
      </div>

      <Link to="/" className="link-back">
        Povratak na početnu
      </Link>

      <Footer />
    </div>
  );
}

export default Services;
