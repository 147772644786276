import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

import exercisekit from "../../files/exercisekit.jpeg";
import girlontable from "../../files/firstimg.png";
import manontable from "../../files/secondimg.jpeg";
import younggirl from "../../files/younggirl.jpeg";
import bellymassage from "../../files/bellymassage.jpeg";

import presoterapija from "../../files/Presoter.pdf";
import limfnadrenaza from "../../files/Limfna drenaža - prikaz.pdf";

import { BsFacebook } from "react-icons/bs";
import { ImMail4 } from "react-icons/im";
import { FiPhoneCall } from "react-icons/fi";

function Footer() {
  let myDate = new Date();

  let someDay = myDate.getDate();
  let someMonth = myDate.getMonth() + 1;
  let someYear = myDate.getFullYear();

  return (
    <div className="footer">
      <div className="footer-box-1">
        <img
          src={younggirl}
          alt="Mlada djevojka — usluga masaže u studiju FizioMaja - održavanje zdravlja i mladolikog izgleda"
          draggable="false"
        />
        <img
          src={girlontable}
          alt="Mlada djevojka na stolu, usluge povezane s fizikalnom terapijom i masažom"
          draggable="false"
        />
        <img
          src={exercisekit}
          alt="Logo studija Maja za fizikalnu terapiju, manualne (ručne) tehnike i fizioterapiju"
          draggable="false"
        />
        <img
          src={manontable}
          alt="Dečko na stolu za masažu, FizioMaja, odnosno studio vlasnice Maje za manualne tehnike, fizioterapiju i održavanje kvalitete tjelesnog zdravlja i života"
          draggable="false"
        />
        <img
          src={bellymassage}
          alt="Masaža po trbuhu - predodžba rada"
          draggable="false"
        />
      </div>

      <div className="footer-box-2">
        <h2>
          FizioMaja, studio za njegu, održavanje tijela i manualne tehnike
        </h2>
        <h3>Vlasnica Maja Zaborski Jovica</h3>
        <h4>Stjepana Radića 40a, 47000 Karlovac</h4>
        <h4>OIB: 51388603829</h4>
      </div>

      <div className="footer-box-1">
        <img
          src={bellymassage}
          alt="Masaža po trbuhu - predodžba rada"
          draggable="false"
        />
        <img
          src={manontable}
          alt="Dečko na stolu za masažu, FizioMaja, odnosno studio vlasnice Maje za manualne tehnike, fizioterapiju i održavanje kvalitete tjelesnog zdravlja i života"
          draggable="false"
        />
        <img
          src={girlontable}
          alt="Mlada djevojka na stolu, usluge povezane s fizikalnom terapijom i masažom"
          draggable="false"
        />
        <img
          src={exercisekit}
          alt="Logo studija Maja za fizikalnu terapiju, manualne (ručne) tehnike i fizioterapiju"
          draggable="false"
        />
        <img
          src={younggirl}
          alt="Mlada djevojka — usluga masaže u studiju FizioMaja - održavanje zdravlja i mladolikog izgleda"
          draggable="false"
        />
      </div>

      <div className="footer-box-3">
        <div className="information">
          <a
            href="https://www.facebook.com/FizioMaja-110349911571459"
            target="_blank"
            rel="noreferrer"
          >
            <h4>
              <BsFacebook className="foot-icon" /> @FizioMaja
            </h4>
          </a>
          <a href="mailto:maja.zaborski@gmail.com">
            {" "}
            <h4>
              <ImMail4 className="foot-icon" /> maja.zaborski@gmail.com
            </h4>
          </a>
          <a href="tel:+385915170453">
            {" "}
            <h4>
              <FiPhoneCall className="foot-icon" /> 091 517 0453
            </h4>
          </a>
        </div>
        <div className="working-time">
          <h3>Radno vrijeme</h3>
          <p>
            Ponedjeljak: <span>14:00 - 20:00</span>
          </p>
          <p>
            Utorak: <span>9:00 - 15:00</span>
          </p>
          <p>
            Srijeda: <span>9:00 - 15:00</span>
          </p>
          <p>
            Četvrtak: <span>14:00 - 20:00</span>
          </p>
          <p>
            Petak: <span>9:00 - 15:00</span>
          </p>
        </div>
      </div>

      <div className="fourth-div"></div>

      <div className="sitemap">
        <h3>SITEMAP</h3>
        <Link to="/">Početna</Link>
        <Link to="/o-nama">O studiju</Link>
        <Link to="/djelatnici">Djelatnici</Link>
        <Link to="/usluge">Usluge</Link>
        <Link to="/usluge/pain-free-program">Pain free</Link>
        <Link to="/usluge/manualne-tehnike">Manualne tehnike</Link>
        <Link to="/usluge/limfna-drenaza">Limfna drenaža</Link>
        <Link to="/usluge/wellness">Wellness</Link>
        <Link to="/kontakt">Kontakt</Link>
        <a href={limfnadrenaza} target="_blank" rel="noopener noreferrer">
          PDF 1
        </a>
        <a href={presoterapija} target="_blank" rel="noopener noreferrer">
          PDF 2
        </a>
        <Link to="/krivi_unos_404">Error (404)</Link>
      </div>

      <div className="fourth-div"></div>

      <div className="fifth-div">
        {`${someDay}. ${someMonth}. ${someYear}.`} Made by{" "}
        <a href="https://mathscript.eu" target="_blank" rel="noreferrer">
          Mathscript
        </a>
        . Sva prava pridržana.
      </div>
    </div>
  );
}

export default Footer;
