import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import "./Wellness.css";

import thigh from "../../files/thigh.png";
import welkotable from "../../files/welkotable.png";
import pilates from "../../files/pilates.png";

import { AiTwotoneTrademarkCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

function Wellness() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="pain-free">
      <Navigation />

      <div className="serv-container">
        <h1>Wellness program</h1>
        <p>
          Zbog profesionalnih izazova izloženi smo stresu koji često dovodi do
          premorenosti, nesanice, loše probave, debljine, nakupljanja celulita,
          starenja kože, kroničnih bolova u vratu i leđima, visokog tlaka i
          srčanih poteškoća…
        </p>
        <p>
          Programom djelujemo na regulaciju tlaka, regulaciju tjelesne težine,
          redukciju masnog tkiva i celulita, regenaraciju i zatezanje kože,
          poboljšanje kvalitete sna, poboljšanje koncentracije, povećanje
          produktivnosti, efikasnosti i učinkovitosti na poslu.
        </p>

        <img
          src={thigh}
          alt="Bolovi u koljenima, kralježnici, zglobovima i drugim dijelovima tijela narušavaju kvalitetu života - studio FizioMaja"
          draggable="false"
        />
        <img
          src={welkotable}
          alt="Masažni moderni stol marke Welko - studio FizioMaja"
          draggable="false"
        />
        <img
          src={pilates}
          alt="Djevojka vježba uz pomoć pilates-lopte - studio FizioMaja"
          draggable="false"
        />

        <ul>
          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Sportska masaža{" "}
            <br />
            <br />
            Intenzivnija pritiskom, potiče opuštanje napete muskulature,
            regeneraciju tkiva i eliminaciju toksina i mliječne kiseline.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Masaža lica{" "}
            <br />
            <br />
            Posebno ugodna i opuštajuća, potiče obnovu kože i smanjenje bora,
            tonizira mišiće lica.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Special relax
            aromatretman <br />
            <br />
            Opuštajuća masaža u trajanju od 70 minuta, uz eterična ulja i
            opuštajuću glazbu; tretman ruku, nogu, leđa i lica, idealna kao
            poklon. Opskrbite svoj um i tijelo pozitivnom energijom oslobađanjem
            od nakupljenog stresa.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Anticelulitni
            tretman <br />
            <br />
            Anticelulitna masaža u kombinaciji sa presoterapijom i body
            wrapingom za maksimalni učinak smanjenja obujma, redukciju masnog
            tkiva i viška tekućine.
          </li>
        </ul>

        <h2>Anticelulitni tretman + presoterapija</h2>
        <p>
          Zbog opsežnog djelovanja na poticanje transporta masnoća, toksina i
          viška tekućine, presoterapija je idealna i u tretmanima mršavljenja.
          Kod nas se koristi u kombinaciji s anticelulitnom masažom i kao takva
          predstavlja visokoučinkovitu metodu u borbi protiv celulita. Izuzetno
          je djelotvorna kod tvrdokornih masnih naslaga jer izbacuje višak
          tekućine iz tkiva što omogućuje bolji dotok krvi i kisika, a i bržu i
          bolju lipolizu. Uzimajući u obzir činjenicu da je celulit i višak
          masnoće jedino moguće izbaciti iz organizma putem limfnog sustava i
          mokraće  jasno je da je ovaj sustav od presudne važnosti održavati
          protočnim i čistim prilikom borbe s celulitom, a osobito prilikom
          redukcije tjelesne težine.
        </p>
        <p>
          Tretman presoterapije-limfne drenaže se odvija bez nanošenja ulja ili
          krema, dakle na čistoj koži ili preko odjeće, izvodi se aparatom uz
          pomoć rukava/nogavice koji se pune zrakom u programiranim vremenskim
          intervalima. Upuhivanjem zraka se stvara pritisak na određene dijelove
          tijela, a međustanična tekućina koja se taložila se tada potiskuje
          natrag u krvotok.
        </p>
        <p>
          Uz pomoć mehaničke limfne drenaže smanjuje se opseg bokova i trbuha,
          stvara se ravnoteža protoka hranjivih tvari i kisika. Smanjuje se
          mogućnost nastanka upale vena i poboljšava se cirkulacija, smanjuje se
          otok nogu, razgrađuje se celulit, učvršćuje se i zateže koža, a svojim
          djelovanjem na autoimuni živčani sustav uklanjanja i stres.
        </p>
        <p>
          Limfna drenaža se izvodi jednom do tri puta tjedno, ovisno o problemu
          koji tretiramo, a preporučuje se ženama i muškarcima koji rade na
          oblikovanju tijela, a žele još bolje rezultate, također ukoliko imate
          problema sa lošom cirkulacijom, venama, celulitom, otečenim i umornim
          nogama posebno ako ste stalno pod stresom, previše sjedite i malo se
          krećete. Ipak u nekim slučajevima ne biste se trebali podvrgnuti
          limfnoj drenaži stoga se svakako prije posavjetujte sa svojim
          terapeutom.
        </p>
        <Link to="/usluge">Povratak na "Usluge"</Link>
        <Link to="/kontakt">Želite li rezervirati termin?</Link>
      </div>

      <Footer />
    </div>
  );
}

export default Wellness;
