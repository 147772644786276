import React from 'react'
import "./SecondImage.css"

function SecondImage() {
  return (
    <div className='second-image'>
       <h3>... najsuvremenije tehnike i metode u području fizikalne terapije</h3>
    </div>
  )
}

export default SecondImage