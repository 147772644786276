import React, { useState } from "react";
import "./HomeReviews.css";

import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

// BsFillArrowLeftCircleFill

function HomeReviews() {
  const [number, setNumber] = useState(1);

  function checkIfVisible() {
    for (let i = 1; i < 6; i++) {
      if (i === number) {
        document
          .querySelector(`blockquote:nth-of-type(${i})`)
          .classList.remove("seen");
        document
          .querySelector(`blockquote:nth-of-type(${i})`)
          .classList.add("seen");
      } else {
        document
          .querySelector(`blockquote:nth-of-type(${i})`)
          .classList.remove("seen");
      }
    }
  }

  function backwardOrder() {
    if (number === 1) {
      setNumber(6);
    }
    setNumber((prevState) => prevState - 1);
    checkIfVisible();
  }

  function forwardOrder() {
    if (number === 5) {
      setNumber(0);
    }
    setNumber((prevState) => prevState + 1);
    checkIfVisible();
  }

  console.log(number);

  return (
    <div className="hr-container">
      <h1>Vaši dojmovi...</h1>
      <div className="home-review">
        <BsFillArrowLeftCircleFill
          className="hw-icon hw-icon-left"
          onClick={backwardOrder}
        />
        <BsFillArrowRightCircleFill
          className="hw-icon hw-icon-right"
          onClick={forwardOrder}
        />

        <blockquote className="seen">
          Oduševljena nakon prve terapije manualne limfne drenaže. Konačno sam
          pronašla na području Ka-županije fizioterapeuta obučenog da radi
          manualnu limfnu drenažu. Stručnost, znanje, pristup... Sve na nivou.
          Svaka preporuka. <br />
          <br />
          Nada L.
        </blockquote>

        <blockquote>
          Vrhunski odrađeno, uz vrlo pristupačnu cijenu. Dogovoren termin unutar
          sat vremena od vremena naručivanja. Najbolja usluga u Karlovcu. Toplo
          preporučujem svima! <br />
          <br />
          Antonio
        </blockquote>

        <blockquote>
          Već nakon prve terapije uočavam znatno poboljšanje u stanju svojih
          leđa. Maja me je detaljno upoznala sa svakim postupkom i dijelom
          terapije. Meni je to puno značilo jer sam se osjećala ugodno, sigurno
          i znala sam što očekivati. Osjećaj nakon terapije je čisti zen.
          Definitivno bih preporučila svima koji imaju problema s leđima, ali i
          onima koji se žele opustiti nakon dugog dana. <br />
          <br />
          Ana P.
        </blockquote>

        <blockquote>
          Dolazila sam u FizioMaja zbog boli u vratu. Već nakon prvog tretmana
          osjetila sam razliku. Bol se smanjila, a sa sljedećim tretmanima u
          potpunosti je nestala. Nemam više osjećaj zatezanja lijeve strane
          vrata i leđa.
          <br /> <br />
          Tea S.
        </blockquote>

        <blockquote>
          Noćima sam se mučila zbog osjećaja trnjenja i boli u nogama. Nakon
          terapije to je stvar prošlosti. Sada mirno spavam i konačno se
          odmorim. <br />
          <br />
          Manuela M.
        </blockquote>
      </div>
    </div>
  );
}

export default HomeReviews;
