import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";

function Navigation() {
  const navRef = useRef();
  let scrolled = window.scrollY;

  /* window.addEventListener("scroll", () => {
        if(window.scrollY < scrolled) {
            navRef.current.style.top = "-100%"
        } else {
            navRef.current.style.top = "0"
        }

        scrolled = window.scrollY
    }) */

  return (
    <div className="navigation" ref={navRef}>
      {/*       <Link to="/o-nama">O nama</Link>
       */}{" "}
      <Link to="/">Početna</Link>
      <Link to="/djelatnici">Djelatnici</Link>
      <Link to="/usluge">Usluge</Link>
      <Link to="/kontakt">Kontakt</Link>
    </div>
  );
}

export default Navigation;
