import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import "./Lymph.css";

import belly from "../../files/bellymassage.jpeg";
import brestcancer from "../../files/brestcancer.png";
import welkotable from "../../files/welkotable.png";
import womansitting from "../../files/womansitting.png";
import lymphdrain from "../../files/lymphdrain.png";

import { AiTwotoneTrademarkCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

function Lymph() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="pain-free">
      <Navigation />

      <div className="serv-container">
        <h1>Limfna drenaža</h1>
        <p>
          Osnovna funkcija limfnog sustava je transport viška tekućine, masti i
          proteina te pročiščavanje krvi. Možemo ga zamisliti kao sustav
          odvodnih kanala koji iz tijela odnosi otpadne tvari prema bubrezima od
          kuda se putem mokraće na kraju izlučuje iz tijela.
        </p>
        <p>
          Dnevno kroz limfni sustav procirkulira oko 2 litre tekućine. Ako iz
          nekog razloga dođe do usporavanja limfnog protoka ili njegove blokade,
          kao posljedica će nastati oteklina → edem.
        </p>
        <p>
          Laganim potiscima ruku u smjeru limfnih puteva, djelujemo kako bismo
          potpomognuli tok limfe, rasteretili limfne čvorove i smanjili edem.
        </p>

        <ul>
          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Ručna limfna
            drenaža po dr. Vodderu <br />
            <br />
            Idealna za smanjenja edema, oteklina, smanjenje boli.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Presoterapija -
            aparaturna limfna drenaža <br />
            <br />
            Kod težine u nogama, kao anticelulitni tretman.
          </li>
        </ul>

        <h2>Ručna limfna drenaža po dr. Vodderu</h2>
        <img
          src={belly}
          alt="Masaža trbuha ženske osobe - studio FizioMaja"
          draggable="false"
        />
        <img
          src={brestcancer}
          alt="Žena s vrpcom u ruci koja simbolizira borbu protiv karcinoma dojke koji je jedan od najvećih uzročnika smrtnosti u žena - studio FizioMaja"
          draggable="false"
        />

        <p>
          Manualna limfna drenaža je jedina terapija koja nema kontraindikacije,
          u isto vrijeme nježna i snažna. Ova tehnika je u potpunosti bezbolna.
        </p>
        <p>
          Područja primjene: primarni limfedem (abnormalnost limfnih žila),
          sekundarni limfedem (nastao nakon estetskih ili drugih kirurških
          zahvata, nakon terapije zračenjem, trauma, karcinoma, infekcija),
          kronična venska insuficijencija, Sudeckova distrofija, neuropatija,
          neuralgija, ishemijska bolest donjih ekstremiteta, dijabetička
          angiopatija, reumatoidni artritis, giht, venski edem, kad želimo
          smanjiti bol i povećati mobilnost tkiva, kad želimo potaknuti tjelesni
          imunoodgovor.
        </p>
        <p>
          Manualna limfna drenaža za navedene dijagnoze se u studiju FizioMaja
          radi ručnom tehnikom ˝po dr. Voderu˝ u sklopu kompletne dekongestivne
          terapije. Izvodi se laganim potiscima ruku u smjeru limfnih puteva
          kako bismo potpomognuli tok limfe, rasteretili limfne čvorove i
          smanjili edem. Tehnika se izvodi u ležećem položaju, bez kontaktnog
          sredstva (ulja), na čistoj koži ili preko tkanine.
        </p>
        <p>
          Ako imate oteklinu, osjećate nelagodu u području ekstremiteta,
          zatezanje kože i sumnjate na neko od gore navedenih stanja javite se
          terapeutu educiranom u području limfne drenaže. Javite se nama!
        </p>

        <h2>Presoterapija – aparaturna limfna drenaža</h2>

        <img
          src={welkotable}
          alt="Masažni moderni stol marke Welko - studio FizioMaja"
          draggable="false"
        />
        <img
          src={womansitting}
          alt="Žena sjedi na podu s prekriženim nogama - studio FizioMaja"
          draggable="false"
        />
        <img
          src={lymphdrain}
          alt="Simbolički crtež limfne drenaže na primjeru presjeka ljudske noge - studio FizioMaja"
          draggable="false"
        />

        <p>
          Kompresivna terapija, presoterapija, IPC, aparaturna limfna drenaža...
          više je naziva za ovu aparaturnu tehniku. Učinak se zasniva na
          inermitentnoj pneumatskoj kompresiji čiji dozirani pritisak
          blagotvorno djeluje na naš organiram. Potiče se protok limfe,
          poboljšava cirkulacija, potiče transport i eliminacija masnih stanica,
          viška vode i toksina.
        </p>
        <p>
          U kombinaciji s manualnom limfnom drenažom je nezamjeniva terapijska
          metoda u liječenju limfedema, venske insuﬁcijencije, postoperativnih
          edema i sportskih ozljeda, te kao proﬁlaksa duboke venske tromboze.
        </p>
        <p>
          Tretman presoterapije-limfne drenaže se odvija bez nanošenja ulja ili
          krema, dakle na čistoj koži ili preko odjeće, izvodi se aparatom uz
          pomoć rukava/nogavice koji se pune zrakom u programiranim vremenskim
          intervalima. Upuhivanjem zraka se stvara pritisak na određene dijelove
          tijela, a međustanična tekućina koja se taložila se tada potiskuje
          natrag u krvotok.
        </p>
        <p>
          Smanjuje se mogućnost nastanka upale vena i poboljšava se cirkulacija,
          smanjuje se otok nogu. Također ukoliko imate problema sa lošom
          cirkulacijom, venama, celulitom, otečenim i umornim nogama posebno ako
          ste stalno pod stresom, previše sjedite i malo se krećete. Limfna
          drenaža se izvodi jednom do tri puta tjedno ovisno o problemu koji
          tretiramo.
        </p>
        <Link to="/usluge">Povratak na "Usluge"</Link>
        <Link to="/kontakt">Želite li rezervirati termin?</Link>
      </div>

      <Footer />
    </div>
  );
}

export default Lymph;
