import React from "react";
import { Link } from "react-router-dom";
import "./HomeServices.css";

import { GrValidate } from "react-icons/gr";
import servicelist from "../../files/Usluge.png";
import prices from "../../files/prices.png";
import poklonbon from "../../files/Poklon bon blu.png";

function HomeServices() {
  return (
    <div className="hs">
      {/*  <div className="home-services">
      <h1>NAŠE USLUGE</h1>
      <ul>
        <li><GrValidate className="hs-icon"/> Pain free program</li>
        <li><GrValidate className="hs-icon"/>Manualne tehnike</li>
        <li><GrValidate className="hs-icon"/>Limfna drenaža</li>
        <li><GrValidate className="hs-icon"/>Wellness program</li>
      </ul>
      <Link to="/usluge">Prikaz detalja</Link>
    </div> */}

      <img
        src={servicelist}
        alt="Popis usluga koje nudi studio FizioMaja"
        draggable="false"
      />
      <img src={prices} alt="Cjenik - FizioMaja" draggable="false" />
      <img
        src={poklonbon}
        alt="Poklon-bon usluga koje nudi studio FizioMaja, moguće skinuti i ostvariti popuste. Potrebna rezervacija pozivom na broj telefona u kontaktima."
        draggable="false"
      />
      <a className="download" href={poklonbon} download>
        Kliknite za preuzimanje poklon-bona
      </a>
    </div>
  );
}

export default HomeServices;
