import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import "./Manual.css";

import younggirl from "../../files/younggirl.jpeg";
import manualtechnic from "../../files/legmassage.png";

import { AiTwotoneTrademarkCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

function Manual() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="pain-free">
      <Navigation />

      <div className="serv-container">
        <h1>Manualne tehnike</h1>
        <p>
          Manualna terapija bavi se sprječavanjem, otkrivanjem i otklanjanjem
          narušenosti međusobnih odnosa struktura i funkcija lokomotornog
          sustava. Manualna terapija temelji svoje postupke i tehnike na analizi
          posture, aktivne i pasivne pokretljivosti zglobova, mišićne
          aktivnosti, motornih stereotipa i analizi bolnih stanja.
        </p>

        <img
          src={younggirl}
          alt="Mlada djevojka prima usluge iz domene manualnih tehnika - studio FizioMaja"
          draggable="false"
        />
        <img
          src={manualtechnic}
          alt="Fizioterapeut, odnosno fizioterapeutkinja sa zaštitnom maskom na radnom mjestu ispred modela kostura - studio FizioMaja"
          draggable="false"
        />

        <ul>
          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Masaža <br />
            <br />
            Individualno prilagođena svakome ponaosob, ovisno o trenutnom
            stanju.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Specifične
            manualne tehnike <br />
            <br />
            Trigger točke, Emmet.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Mobilizacija,
            trakcija, manipulacija <br />
            <br />
          </li>
        </ul>
        <Link to="/usluge">Povratak na "Usluge"</Link>
        <Link to="/kontakt">Želite li rezervirati termin?</Link>
      </div>

      <Footer />
    </div>
  );
}

export default Manual;
