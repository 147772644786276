import React from "react";
import "./HomeWorkers.css";

import majaimg from "../../files/maja.jpeg";
import workplace from "../../files/workplace.jpeg";

function HomeWorkers() {
  return (
    <div className="home-workers">
      <h1>DJELATNICI</h1>

      <h3>
        Maja Zaborski Jovica, bacc. physioth, licencirani fizioterapeut, TT
        praktičar, certif. limfoterapeut s dugogodišnjim radnim iskustvom,
        glavni terapeut
      </h3>

      <img
        src={majaimg}
        alt="Vlasnica obrta Maja Zaborski-Jovica"
        draggable="false"
        className="maja-image"
      />

      <p>
        2003. upisuje <span>srednju medicinsku školu</span> u Karlovcu. Tijekom
        školovanja ostvaruje izvrsne rezultate te 2007. sudjeluje na državnom
        natjecanju učenika medicinskih <span>škola Schola Medica</span> 2007. u
        Vinkovcima.
      </p>
      <p>
        2007. upisuje studij fizioterapije na{" "}
        <span>Zdravstvenom veleučilištu u Zagrebu</span>. Kroz brojne stručne
        prakse – KB Sv. Duh, KBC Sestre milosrdnice, KB Dubrava, KBC Zagreb,
        Spec. bolnica za medicinsku rehabilitaciju Krapinske toplice... stječe
        mnoga znanja i vještine i stručni naziv{" "}
        <span>stručna prvostupnica (baccalaurea) fizioterapije</span>.
      </p>
      <p>
        2011./2012. odrađuje <span>pripravnički staž na KBC-u Zagreb</span>, na
        odjelima fizikalne medicine i rehabilitacije, reumatologije,
        ginekologije i porodništva, neurokirurgije, kardiologije i pedijatrije.
      </p>
      <p>
        2012. pred ispitnim povjerenstvom Ministarstva zdravlja Republike
        Hrvatske uspješno polaže stručni ispit te zaslužuje{" "}
        <span>Licencu za samostalan rad</span>.
      </p>
      <p>
        2012. - 2021. Svoje radno iskustvo dalje prikuplja kroz predan rad u
        privatnim praksama za fizikalnu terapiju te uz to kroz niz{" "}
        <span>edukacija trajnog usavršavanja</span> konstantno stječe nova
        znanja i vještine iz područja struke.{" "}
      </p>
      <p>
        2012. sudjelovanje na drugom{" "}
        <span>Internacionalnom kongresu neurorazvojne terapije</span> u
        Ljubljani, Slovenija
      </p>
      <p>
        2013. <span>˝EMM-TECH radionica˝</span>, Emmet therapies, Zagreb
      </p>
      <p>
        2017. <span>˝Trigger točke radionica˝</span>, u organizaciji Hrvatskog
        zbora fizioterapeuta, Zagreb
      </p>
      <p>
        2018. Edukacija <span>˝Manualna limfna drenaža˝</span> - 40 sati u
        organizaciji Centra za limfnu drenažu u Zagrebu
      </p>
      <p>
        Cilj u radu su joj zadovoljni klijenti i što uspješnije rješavanje
        problema s područja sustava organa za pokretanje (mišići, kosti,
        zglobovi) i limfnog sustava.
      </p>
      <p>
        Naša vizija: stručnost, posvećenost klijentu u radu 1 na 1, mir,
        privatnost, bez gužvi, bez dugih čekanja termina, prilagođeno svakome po
        njegovim potrebama, najkvalitetnija aparatura, posebne ponude.
      </p>

      {/* <div className="work-overlay">
        <img
          src={workplace}
          alt="Radno mjesto obrta Maja Zaborski-Jovica"
          draggable="false"
          className="work-image"
        />
        <p>Radno mjesto studija FizioMaja</p>
      </div> */}
    </div>
  );
}

export default HomeWorkers;
