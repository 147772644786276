import React, { useEffect } from "react";
import "./Workers.css";
import { Link } from "react-router-dom";

import Navigation from "../Navigation/Navigation";
import HomeWorkers from "../HomeWorkers/HomeWorkers";
import Footer from "../Footer/Footer";

import logo from "../../files/logo.png";

function Workers() {
  useEffect(() => {
    window.scroll(0, 0);

    if (window.FB) {
        window.FB.XFBML.parse();
    }
  }, []);

  return (
    <div className="workers">
      <Navigation />

      <div className="workers-intro">
        <h1>Djelatnici studija</h1>
        <img
          src={logo}
          alt="Logo studija FizioMaja za usluge fizikalne terapije, manualne tehnike i druge oblike pomoći za unaprjeđenje fizičkog zdravlja i prevencije bolova u kostima, zglobovima, mišićima i kod drugih ozljeda"
          draggable="false"
        />
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      <HomeWorkers />

      <div className="face-position">
        <div
          class="fb-page"
          data-href="https://www.facebook.com/FizioMaja-110349911571459"
          data-tabs="timeline"
          data-width="300"
          data-height="650"
          data-small-header="false"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/FizioMaja-110349911571459"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/FizioMaja-110349911571459">
              FizioMaja
            </a>
          </blockquote>
        </div>
      </div>

      <Link to="/" className="link-back">
        Povratak na početnu
      </Link>

      <Footer />
    </div>
  );
}

export default Workers;
