import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import "./PainFree.css";

/* import skeleton from "../../files/skeleton.png";
 */

import knees from "../../files/knees.jpeg";

import { AiTwotoneTrademarkCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

function PainFree() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="pain-free">
      <Navigation />

      <div className="serv-container">
        <h1>Pain free program</h1>
        <p>
          Svatko od nas s vremena na vrijeme trpi bolove u mišićima ili
          zglobovima. Često smo pokočeni u području vrata i ramena ili pak
          osjećamo bolove u donjem dijelu leđa. Nekad se dogodi nezgoda (uganuće
          zgloba, ruptura mišića), a nekad više ni sami ne znamo od kuda je
          počelo, a sada već dugo trpimo vrtoglavice, glavobolje, utrnulost,
          smanjenu pokretljivost...
        </p>
        <p>
          Pain free program zasniva se na tretmanima i rehabilitaciji
          prilagođenoj pojedincu ovisno o trenutnim problemima i potrebama, ali
          i procjeni fizioterapeuta uz uvid u medicinsku dokumentaciju.
        </p>
        <p>
          Pomnim odabirom manualnih i vrhunskih aparaturnih tehnika (BTL i
          Pulsus Medical uređaji vrhunske kvalitete i učinkovitosti) kroz
          individualni pristup uspješno rješavamo razne vrste boli, mišičnu
          napetost i druge simptome.
        </p>
        <img
          src={knees}
          alt="Bolovi u koljenima, kralježnici, zglobovima i drugim dijelovima tijela narušavaju kvalitetu života - studio FizioMaja"
          draggable="false"
        />
        {/*   <img
          src={skeleton}
          alt="Fizioterapeut, odnosno fizioterapeutkinja sa zaštitnom maskom na radnom mjestu ispred modela kostura - studio FizioMaja"
          draggable="false"
        /> */}

        <ul>
          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Terapijski
            ultrazvuk <br />
            <br />
            Smanjenje boli, vraćanje elastičnosti tkiva, smirivanje upale,
            povećanje mikrocirkulacije, poticanje lokalnog metabolizma...
            Područje primjene: cervikalni bolni sindrom, lumbalni bolni sindrom,
            kronične degenerativne bolesti, burzitis, tendinitis, kapsulitis,
            epikondilitis, entenzitis, povrede ligamenata, bolno i ukočeno rame,
            radijalni i ulnarni epikondilitis (teniski, golferski lakat).
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" />{" "}
            Elektrostimulacija <br />
            <br />
            Fizikalna procedura kojom pomoću električnih podražaja izazivamo
            mišićnu kontrakciju. Provodi se sa svrhom stimulacije reaktivnosti
            mišića, mišićnoga tonusa i razvoja mišićne snage nakon ozljeda. U
            novije se vrijeme koristi i za uspješniji oporavak mišića nakon
            opterećenja.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Magnet <br />
            <br />
            Potiče regeneraciju tkiva. Prodiranjem magnetskog polja kroz tijelo
            zahvaćena je svaka stanica koja se nalazi unutar tog polja. Pokreću
            se ioni u stanici i izvan nje te se ubrzava prolazak iona kroz
            staničnu membranu što pozitivno utječe na metabolizam stanice.
            Magnetsko polje normalizira razlike električnog potencijala stanice
            i poboljšava opskrbu stanice kisikom što u konačnici znači brže
            cijeljenje tkiva. Područje primjene se odnosi na kontuzije,
            istegnuća, ozljede ligamenata, luksacije, distorzije, frakture,
            pseudoartroze, Sudeckova distrofija, reumatske bolesti akutna i
            kronična stanja), postoperativna rehabilitacija - za zacjeljivanje
            rana, bolesti središnjeg i perifernog živčanog sustava.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Manualne tehnike{" "}
            <br />
            <br />
            Glavnu ulogu u manualnim tehnikama imaju ruke terapeuta. Primjenjuje
            se točno određena, konkretno usmjerena sila ruku na tijelo u cilju
            poboljšanja mobilnosti u zglobovima, vezivnom tkivu ili u skeletnim
            mišićima.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Individualne
            vježbe <br />
            <br />
            Prilagođene vježbe, ovisno o trenutnom stanju i potrebama klijenta.
            Izvode se s ciljem vraćanja neophodne ravnoteže u odnose među
            mišićima, vraćanja fleksibilnosti, poboljšanja koordinacije,
            ravnoteže ili propriocepcije.
          </li>

          <li>
            <AiTwotoneTrademarkCircle className="serv-icon" /> Kinesiotaping{" "}
            <br />
            <br />
            Atraktivna tehnika za poboljšanje mišićne funkcije, redukciju boli,
            poticanje limfnog toka. Pruža podršku u stabilizaciji ozlijeđenog
            područja, ima učinak na različite receptore unutar somatosenzornog
            sustava, smanjuje bolna stanja i olakšava limfnu drenažu
            mikroskopskim zatezanjem kože. Upravo ovaj učinak zatezanja povećava
            intersticijski prostor (prostor među stanicama) i omogućuje
            smanjenje upale na pogođenim područjima.
          </li>
        </ul>
        <Link to="/usluge">Povratak na "Usluge"</Link>
        <Link to="/kontakt">Želite li rezervirati termin?</Link>
      </div>

      <Footer />
    </div>
  );
}

export default PainFree;
