import React from "react";
import "./HomeAbout.css";

import studiomaja from "../../files/studiomaja.png";

function HomeAbout() {
  return (
    <div className="home-about">
      <div className="home-about-first">
        <h3>STUDIO FizioMaja</h3>
        <img
          src={studiomaja}
          alt="Logo i službeni simbol studija za njegu, manualne tehnike, masažu i fizikalnu terapiju FizioMaja"
          draggable="false"
        />
      </div>
      <div className="home-about-second">
        <div className="inner">
          <p>
            <span>O nama. </span>
            FizioMaja je suvremeno opremljen studio za njegu, održavanje tijela
            i manualne tehnike.
          </p>
        </div>

        <div className="inner">
          <p>
            <span>Stručnost. </span>
            Specijalizirani smo za tretman osoba s poremećajima limfnog toka, s
            bolnim stanjima mekih tkiva i zglobova, promicanje zdravlja,
            prevenciju ozljeda i tretmane održavanja tijela.
          </p>
        </div>
        <div className="inner">
          <p>
            <span>Kvalitetniji život. </span>
            Posebna pozornost usmjerena je podizanju kvalitete života, pravilnom
            držanju i kretanju tijela. Ali isto tako otklanjanju već postojećih
            bolova, narušenih mišićnih odnosa i oštećenja tkiva.
          </p>
        </div>
        <div className="inner">
          <p>
            <span>Individualan pristup. </span>
            Pristup svakoj osobi je individualan uz primjenu najsuvremenijih
            tehnika i metoda u fizikalnoj terapiji. Tretmani se provode u
            dogovoreno vrijeme koje se prilagođava potrebama i životnom ritmu
            korisnika u odnosu 1 terapeut na 1 klijenta.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeAbout;
