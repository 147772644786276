import React from "react";
import "./FirstImage.css";

import logo from "../../files/logo.png"

export default function FirstImage() {
  return (
    <div className="first-image">
      <img
        src={logo}
        alt="Logo studija FizioMaja za njegu i pružanje usluga fizikalne terapije"
        draggable="false"
      />
      <h1>FizioMaja, studio za njegu, održavanje tijela i manualne tehnike</h1>
      <h3>vl. Maja Zaborski Jovica</h3>
    </div>
  );
}
