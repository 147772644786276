import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import "./Contact.css";
import { Link } from "react-router-dom";

import logo from "../../files/logo.png";
import work from "../../files/work.png";
import prices from "../../files/prices.png";
import motivation from "../../files/motivation.png";

import { BsFacebook } from "react-icons/bs";
import { ImMail4 } from "react-icons/im";
import { FiPhoneCall } from "react-icons/fi";

function Contact() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="contact">
      <Navigation />

      <div className="contact-intro">
        <h1>Kontaktirajte nas</h1>
        <img src={logo} alt="Logo studija FizioMaja" draggable="false" />
      </div>

      <div className="contact-containers">
        <div className="contact-container">
          <h1>Pitajte nas bez odgode, sve što vas zanima. Odgovoramo brzo.</h1>
          <div className="work-hour">
            <a
              href="https://www.facebook.com/FizioMaja-110349911571459"
              target="_blank"
              rel="noreferrer"
            >
              <h4>
                <BsFacebook className="foot-icon" /> @FizioMaja
              </h4>
            </a>
            <a href="mailto:maja.zaborski@gmail.com">
              {" "}
              <h4>
                <ImMail4 className="foot-icon" /> maja.zaborski@gmail.com
              </h4>
            </a>
            <a href="tel:+385915170453">
              {" "}
              <h4>
                <FiPhoneCall className="foot-icon" /> 091 517 0453
              </h4>
            </a>
          </div>
        </div>

        <div className="contact-container">
          <h1>Nalazimo se u Stjepana Radića 40A u Karlovcu (47 000)</h1>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.098798278376!2d15.553836115558246!3d45.48795517910127!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47643c34995b86cd%3A0x33d7d04d6db4dd2b!2sUl.%20Stjepana%20Radi%C4%87a%2040A%2C%2047000%2C%20Karlovac!5e0!3m2!1sen!2shr!4v1651868406455!5m2!1sen!2shr"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="cont-map"
          ></iframe>
        </div>
        <div className="contact-container">
          <h1>
            Dostupni smo u radno vrijeme koje vam je ovdje navedeno. <br />
            <br />{" "}
            <span style={{ color: "var(--primary-colour)" }}>
              Obavezna prethodna rezervacija termina
            </span>
          </h1>
          <div className="work-hours">
            <img
              src={work}
              alt="Radno vrijeme studija FizioMaja"
              draggable="false"
            />
          </div>
        </div>

        <div className="contact-container">
          <h1>
            Upite oko cijene paketa dodatno razriješite putem nekih od
            komunikacijskih kanala.
          </h1>
          <div className="work-hours">
            {/* <img
              src={}
              alt="Cjenik studija FizioMaja"
              draggable="false"
            /> */}
          </div>
        </div>

        <div className="contact-container">
          <h1>Opustite se i uživajte u svojoj masaži</h1>
          <div className="work-hours">
            <img
              src={motivation}
              alt="Motivacijska poruka da bi se korisnici odvažili na odabir usluge i učinili nešto za svoje zdravlje i opustili se od svakodnevnih briga uz studio FizioMaja"
              draggable="false"
            />
          </div>
        </div>
      </div>

      <Link to="/" className="link-back">
        Povratak na početnu
      </Link>

      <Footer />
    </div>
  );
}

export default Contact;
