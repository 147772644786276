import React, { useEffect } from "react";
import "./HomePage.css";

import Navigation from "../../components/Navigation/Navigation";
import FirstImage from "../FirstImage/FirstImage";
import SecondImage from "../SecondImage/SecondImage";
import HomeAbout from "../HomeAbout/HomeAbout";
import HomeWorkers from "../HomeWorkers/HomeWorkers";
import HomeServices from "../HomeServices/HomeServices";
import HomeReviews from "../HomeReviews/HomeReviews";
import Footer from "../Footer/Footer";

function HomePage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Navigation />
      <FirstImage />
      <SecondImage />
      <HomeAbout />
      {/* <HomeWorkers /> */}
      <HomeServices />
      <HomeReviews />
      <Footer />
    </div>
  );
}

export default HomePage;
